<template>
  <div class="page-container">
    <h1>Welcome to takk.net</h1>
    <p>This is a site for homeschool resources, starting with math worksheets.</p>
  </div>
</template>

<script>
  export default {
    components: {
    },
  }
</script>
