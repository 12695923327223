<template>
    <div>
        <message-container class="non-printable">
        </message-container>
        <nav-menu class="non-printable">
        </nav-menu>
        <v-main>
          <router-view/>
        </v-main>
    </div>
</template>

<script>
    import NavMenu from "@/components/global/NavMenu.vue"
    import MessageContainer from "@/components/global/MessageContainer.vue"

    export default {
        name: 'Takk',
        components: {
            NavMenu,
            MessageContainer
        }
    }
</script>

<style scoped>
    
</style>