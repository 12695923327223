<template>
    <div>
        <v-banner v-for="alert in alerts" :key="alert.id">
            <div class="parent" :class="[alert.style]">
                <v-icon slot="icon" color="blue" size="32">mdi-{{alert.icon}}</v-icon>
                <span class="alert-text">{{alert.text}}</span>
                <v-btn v-if="alert.action != null" @click="perform(alert.action)">{{alert.actionText}}</v-btn>
            </div>
        </v-banner>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                alerts: [  ]
            }
        },
        methods : {
            perform: function(action) {
                alert(`${action} is not implemented yet`)
            }
        }
    }
</script>

<style scoped>
 .alert-text {
     font-size: 20px;
 }   
 .parent {
     padding: 16px 32px;
     margin: 0 auto;
     text-align: center;
 }
 .notification {
     background-color: #C0C0C0;
 }
 .warning {
     background-color: #DDBB00;
 }
 .error {
     background-color: #900000;
 }
</style>