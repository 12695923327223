<template>
    <v-app-bar>
        <v-avatar :tile="true" size="96">
            <img src="@/assets/logo.svg" alt="logo">
        </v-avatar>

        <v-toolbar-items>
            <v-btn
                v-for="navigationItem in getNavigationItems()"
                text
                :key="`${navigationItem.name}`"
                :to="navigationItem.path"
            >
                <span>{{navigationItem.name}}</span>
            </v-btn>
                
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
    import { getNavigationItems } from "@/router/index.js"

    export default {
        methods: {
            getNavigationItems
        }
    }
</script>

<style scoped>
    .logo {
        width: auto;
        height: 40px;
    }
</style>