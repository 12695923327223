<template>
    <table class="top print-black" :class="[size]">
        <tr class="multiplicand-container">
            <td :class="[size]">
            </td>
            <td :class="[size]">
            </td>
            <td :class="[size]" class="multiplicand">
                {{multiplicand}}
            </td>
        </tr>
        <tr class="multiplier-container">
            <td :class="[size]">
                x
            </td>
            <td :class="[size]" class="multiplicand-container-spacer">
            </td>
            <td :class="[size]" class="multiplier">
                {{multiplier}}
            </td>
        </tr>
    </table>
</template>

<script>
import { rand } from "@/script/util.js"

export default {
    data: function() {
        return {
            multiplicand: 0,
            multiplier: 0
        }
    },
    props: {
        highNumber:Number, 
        lowNumber:Number,
        size: { 
            type: String,
            required: true,
            default: "normal"
        }
    },
    created: function() {
        let totalPossibilities = this.summation(this.highNumber) + this.lowNumber + 1

        let chosenPossibility = rand(totalPossibilities)
        let numbers = this.findNumbers(chosenPossibility, this.highNumber - 1)

        let test = rand(1)
        this.multiplicand = numbers[test]
        this.multiplier = numbers[1-test]
    },
    methods: {
        findNumbers: function(chosenPossibility) {
            let remaining = chosenPossibility

            for (let i = 1; i < this.highNumber; i++)
            {
                for (let j = 1; j <= i; j++)
                {
                    if (remaining == 0)
                    {
                        return [i,j]
                    }
                    remaining--
                }
            }

            for (let i = 1; i <= this.lowNumber; i++)
            {
                if (remaining == 0)
                    return [this.highNumber, i]
                
                remaining--
            }

            return [0, remaining]
        },
        summation(num) {
            let ret = 0
            for (let i = 1; i <= num; i++)
                ret += i

            return ret
        }
    }
}
</script>

<style scoped>
    .top.small {
        border-collapse: collapse;
        margin: 0px 32px 56px 32px;
    }
    .top.normal {
        border-collapse: collapse;
        margin: 0px 40px 72px 40px;
    }
    td.small {
        font-size: 28px;
        font-family: "Comic Sans MS";
    }
    td.normal {
        font-size: 36px;
        font-family: "Comic Sans MS";
    }
    .multiplier-container {
        border-bottom: 2px solid;
    }
    .multiplier, .multiplicand {
        text-align: right;
    }
    .multiplicand-container-spacer {
        min-width: 8px;
    }
</style>