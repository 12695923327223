<template>
    <v-container>
        <div v-if="hasProperties"> 
            <!--h1 class="non-printable" vi-if="header">{{header}}</h1-->
            <table v-for="pageIndex in values.pages" :key="'page' + pageIndex" class="complete-printed-page">
                <tr v-if="hasHeader">
                    <td :colspan="columns">
                        <div class="header-container">
                            <div class="left-header" :class="problemSize">{{values.leftHeader}}</div>
                            <div class="header-spacer"></div>
                            <div class="right-header" :class="problemSize">{{values.rightHeader}}</div>
                        </div>
                    </td>
                </tr>
                <tr v-for="rowIndex in rows - 1" :key="'row' + rowIndex">
                    <td v-for="colIndex in columns" :key="rowIndex + 'row' + colIndex + 'col' + colIndex">
                        <multiplication-problem 
                            :high-number="values.highNumber" 
                            :low-number="values.lowNumber"
                            :size="problemSize"
                        >
                        </multiplication-problem>
                    </td>
                </tr>
                <tr>
                    <td v-for="colIndex in columnsInLastRow" :key="'lastRow' + colIndex">
                        <multiplication-problem 
                            :high-number="values.highNumber" 
                            :low-number="values.lowNumber"
                            :size="problemSize"
                        >
                        </multiplication-problem>
                    </td>
                </tr>
            </table>
        </div>
        <v-card v-else class="generate-worksheets-card">
            <v-card-text>
                <v-container>   
                    <v-row>    
                        <v-col cols="12">       
                            <h1 class="non-printable">Generate Worksheets</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs.highNumber"
                                label="High Number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs.lowNumber"
                                label="Low Number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs.perPage"
                                label="How many problems do you want per page?"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs.pages"
                                label="How many pages do you want?"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                        <h3>Headers (Optional)</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols=6>
                            <v-text-field
                            v-model="inputs.leftHeader"
                            label="Header to appear on the top left of each page? (optional)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="inputs.rightHeader"
                                label="Header to appear on the top right of each page? (optional)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                @click="generateWorksheets"
                            >
                                Generate Worksheets
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import MultiplicationProblem from "@/components/math/worksheet/MultiplicationProblem.vue"

    export default {
        data: () => ({
            inputs:
            {
                highNumber: null,
                lowNumber: null,
                perPage: null,
                pages: null,
                leftHeader: null,
                rightHeader: null
            },
            values:{
                highNumber: null,
                lowNumber: null,
                perPage: null,
                pages: null,
                leftHeader: null,
                rightHeader: null
            }
        }),
        props: {
            highNumber: Number,
            lowNumber: Number,
            perPage: Number,
            pages: Number,
        },
        created : function() {
            this.values.highNumber = this.highNumber
            this.values.lowNumber = this.lowNumber
            this.values.perPage = this.perPage
            this.values.pages = this.pages
        },
        components : {
            MultiplicationProblem
        },
        computed : {
            hasProperties: function () { 
                return !isNaN(this.values.highNumber) && !isNaN(this.values.lowNumber) && !isNaN(this.values.perPage) && !isNaN(this.values.pages)
            },
            columns: function () { return Math.ceil(Math.sqrt(this.values.perPage)) },
            rows: function () { return Math.ceil(this.values.perPage / Math.ceil(Math.sqrt(this.values.perPage))) },
            columnsInLastRow: function() { return this.values.perPage - (this.columns * (this.rows - 1))},
            problemSize: function() { return this.values.perPage < 50 ? "normal" : "small"},
            hasHeader: function() { return this.values.leftHeader || this.values.rightHeader }
        },
        methods: {
            generateWorksheets: function()
            {
                this.values.highNumber = parseInt(this.inputs.highNumber)
                this.values.lowNumber = parseInt(this.inputs.lowNumber)
                this.values.perPage = parseInt(this.inputs.perPage)
                this.values.pages = parseInt(this.inputs.pages)
                this.values.leftHeader = this.inputs.leftHeader
                this.values.rightHeader = this.inputs.rightHeader
            }
        }
    }
</script>

<style scoped>
.header-container {
    display: flex;
}

.header-spacer {
    flex: 1;
}

.left-header {
    border-collapse: collapse;
    font-family: "Comic Sans MS";
    flex: auto;
    text-align: left;
}

.left-header.small {
    margin: 16px 32px 16px 32px;
    font-size: 28px;
}
.left-header.normal {
    margin: 16px 48px 16px 48px;
    font-size: 36px;
}
.right-header {
    border-collapse: collapse;
    font-family: "Comic Sans MS";
    flex: auto;
    text-align: right;
}
.right-header.small {
    margin: 16px 32px 16px 32px;
    font-size: 28px;
}
.right-header.normal {
    margin: 16px 48px 16px 48px;
    font-size: 36px;
}

.generate-worksheets-card {
    width: 67%;
    max-width: 750px; 
    margin: 0 auto;
}
</style>